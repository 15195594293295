<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div v-if="selectedView === '1'" key="1">
        <v-flex class="mt-5">
          <h1 class="mb-6">
            Reports
          </h1>
          <v-spacer />
        </v-flex>
        <v-card>
          <apexchart
            v-if="!showProgress"
            :height="300"
            type="bar"
            :options="disconnectionsChart"
            :series="disconnectionsChartSeries"
          />
        </v-card>
        <v-card>
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <div>Gateway disconnections</div>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="gwDisconnections"
            :search="search"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.startTime="{ item }">
              {{ mapTime(item.startTime) }}
            </template>
            <template v-slot:item.endTime="{ item }">
              {{ mapTime(item.endTime) }}
            </template>
          </v-data-table>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <div>Gateway ADD failures</div>
          </v-card-title>
          <v-data-table
            :headers="headersGatewayAddFailures"
            :items="gatewayAddFailures"
            :search="search"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.permissionGranted="{ item }">
              <v-checkbox v-model="item.permissionGranted" />
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>

import dayjs from 'dayjs'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'Reports',
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      selectedView: '1',
      showProgress: false,
      btnLoading: false,
      pagination: {},
      search: '',
      gwDisconnections: [],
      gatewayAddFailures: [],
      headers: [
        { text: '#', value: 'id' },
        { text: 'Property Id', value: 'propertyId' },
        { text: 'Main User', value: 'mainUserEmail' },
        { text: 'DevVer', value: 'devModel' },
        { text: 'MAC', value: 'ieee' },
        { text: 'Start Time', value: 'startTime' },
        { text: 'End Time', value: 'endTime' }
      ],
      footerProps: {
        'items-per-page-options': [10, 30, 50, 100]
      },
      headersGatewayAddFailures: [
        { text: '#', value: 'id' },
        { text: 'Property Id', value: 'propertyId' },
        { text: 'User ID', value: 'userId' },
        { text: 'User Email', value: 'userEmail' },
        { text: 'gatewayModel', value: 'selectedGatewayModel' },
        { text: 'selectedWifi', value: 'selectedWifi' },
        { text: 'gatewayIp', value: 'gatewayIp' },
        { text: 'tcpPort', value: 'tcpPort' },
        { text: 'status', value: 'status' },
        { text: 'progress', value: 'progress' },
        { text: 'permissionGranted', value: 'permissionGranted' }
      ],
      disconnectionsChartSeries: [],
      disconnectionsChart: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '100%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          // tickAmount: 'dataPoints'
        }
      }
    }
  },
  watch: {
    // pagination: {
    //   deep: true,
    //   handler () {
    //     this.loadRecords()
    //   }
    // },
    // search () {
    //   if (this.search.length >= 3 || this.search.length === 0) {
    //     this.loadLandlords()
    //   }
    // }
  },
  mounted () {
    this.getReports()
  },
  methods: {
    async getReports () {
      this.showProgress = true
      this.$store.dispatch('getGwDisconnections').then(response => {
        this.gwDisconnections = response.data.disconnections
        this.disconnectionsChartSeries.push(response.data.disconnectionsPerProperty)
        this.$store.dispatch('getGatewayFailuresLog').then(response => {
          this.gatewayAddFailures = response.data
          this.showProgress = false
        })
      })
    },
    mapTime (time) {
      if (!time) {
        return 'N/A'
      } else {
        const date = Math.round(time / 1000)
        return dayjs.unix(date).format('DD/MM/YYYY @ HH:mm')
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
